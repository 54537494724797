<template>
    <div class="forgot-password">
        <div class="container">
            <div class="content">
                <h3>{{this.send ? '信件已寄出' : '忘記密碼'}}</h3>
                <p>{{ this.send ? '請' : '請填入您的信箱，並' }}依照信件的指示完成密碼重設。</p>
                <p v-if="this.send">將於 {{ toHome }} 秒後回到首頁。</p>
                <GlobalInput v-if="!this.send" type="text" :placeholder="$t('text.pleaseInputYourEmail')" v-model="email" />
                <button v-if="!this.send" @click="resetPassword()">{{$t('form.submit')}}</button>
            </div>
        </div>
    </div>
</template>
<script>

import GlobalInput from '@/components/GlobalInput'
import {apiResetPasswordRequest} from '@/apis/account.js'

export default {
    name: 'ForgotPassword',
    components: {
        GlobalInput
    },
    data() {
        return{
            email: '',
            send: false,
            toHome: 5
        }
    },
    methods: {
        backToHome(){
            setInterval(() => {
                this.toHome -= 1;
                if(this.toHome == 0)window.location = '/';
            }, 1000)
        },
        resetPassword(){
            apiResetPasswordRequest({
                email: this.email
            }).then(res => {
                if(res.data.status){
                    this.send = true
                    this.backToHome()
                }else{
                    this.$toast.error(res.data.msg, {
                        timeout: 2000
                    })
                }
            })
        }

    },
    mounted() {
    },
}
</script>